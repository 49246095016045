<template>
  <div class="side-nav-container">
    <div class="nav-block-wrap">
      <hr>
      <template v-if="enabledPluginNavs.length > 0">
        <ul v-for="(nav,idx) of enabledPluginNavs" :key="idx" class="nav nav-block flex-column active">
          <li v-if="nav.enabled" class="nav-item">
            <a href="#" aria-current="page" class="nav-link open router-link-exact-active router-link-active">
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
              {{ nav.name }}
            </a>
            <ul v-for="(sub_section,index) of nav.sub_sections" :key="index" class="nav flex-column">
              <li v-if="sub_section.enabled" class="nav-item">
                <router-link :to="{ name: sub_section.url_path, params: sub_section.params}" class="nav-link" @click.prevent="toggleSideNav">
                  {{ sub_section.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";

export default {
emits: ['toggle'],
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledPluginNavs() {
      const params = this.$route.params;
      let subSections = [];

      subSections = [
        {
          name: 'Comms Templates',
          url_path: 'generate-instance',
          permission_key: PermissionNames.INTERNAL_MATCH_COMMUNICATION_TEMPLATES,
          enabled: false,
          params: {
            donor: params.donor,
            match: params.match,
            txc: params.txc,
            organGroup: params.organGroup,
          }
        }
      ]

      const PluginAreaNavs = [
        {
          name: 'Match Links',
          sub_sections: subSections,
          enabled: false
        },
      ];

      if(!this.user || !this.userPermissions) return [];
       return PluginAreaNavs.map((nav_item) => {
        nav_item.sub_sections.map((sub) =>{
          const nav_perm = this.userPermissions[sub.permission_key];
          sub.enabled = nav_perm ? (nav_perm.read || false) : false;
          nav_item.enabled = nav_item.enabled || sub.enabled ? true : false;
          return sub;
        })
        return nav_item;
      })
    },
  },
  methods: {
    toggleSideNav() {
      const innerWidth = window.innerWidth;
      if (innerWidth < 991) {
        this.$emit('toggle');
      }
    }
  }
}
</script>
