<template>
  <div class="form-group" :class="colStyle">
    <Field
      v-model.trim="getValue"
      v-slot="{ errors, field }" 
      :name="name"
      :rules="rules">
      <label :for="name">{{label}} <span v-if="isRequired" class="text-danger">*</span></label>
      <input
        :name="name" 
        v-bind="field" 
        @input="$emit('update:modelValue', $event.target.value)"
        type="text"
        :class="{
          'is-invalid': !disabled && hasErrors(errors), 
          'form-control': !readonly, 
          'form-control-plaintext': readonly,
          }"
        :readonly="readonly||disabled"
      />
      <InvalidFeedback :name="getErrorName" :errors="errors"/>
    </Field>
  </div>
</template>

<script>
import { useForm, Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import { formMixin } from "@/src/mixins/formMixin.js";

export default {
  components: {
    InvalidFeedback,
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    colStyle: { default: 'form-group-standard-6-column-medium-up' },
  },
  emits: ['update:modelValue'],
  mixins: [ formMixin ],
}
</script>

