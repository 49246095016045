import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import * as AllRules from '@vee-validate/rules';
import { errorMessages } from './errorMessages';

/**
 * Registration of all global validators.
 */
Object.keys(AllRules).forEach(rule => {
  if (typeof AllRules[rule] === 'function') {
      defineRule(rule, AllRules[rule]);
  }
});

configure({
  // Generates an English message locale generator
  generateMessage: localize(errorMessages)
});
