<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError" :custom-header="true">
    <template #header>
      <div class="card-header media" style="display: flex; align-items: center">
        <div class="media-body">
          <div class="card-title d-flex">
            <span> Manage On-Call {{ selectedUser ? `- ${selectedUser.profileInfo.full_name}` : '' }}</span>
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedUser" @submit="onSubmit">
            <OnCallState :user="selectedUser" 
              :show-group-warning="showWarning" 
              @reload="reload" />

            <br>
            <h5 class="legend-title card-title">
              On-call Selections
            </h5>
            <PortalTable ref="super_team_service_level_index" table-id="super_team_service_level_index" mode="local"
              :table-data="records" :loading="isSavableStateLoading" edit-mode="cell" 
              :sort-order="1"
              sort-field="serviceLevel" empty="No Teams Groups!" :filter-values="filterValues" 
              filter-display="row"
              @on-sort="handleSortChange"
              @on-filter-change="onFilterChange">
              <template #columns>
                <Column field="teamGroup" header="Team-Group" filter-field="teamGroup" 
                  sortable
                  :show-filter-menu="false">
                  <template #filter="{ filterModel = { value: null }, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter"
                      placeholder="Search by Team or Group" @input="filterCallback()" />
                  </template>
                </Column>
                <Column field="serviceLevel" header="Service Level" filter-field="serviceLevel" 
                  sortable
                  :show-filter-menu="false" :filter-menu-style="{ width: '14rem' }">
                  <template #filter>
                    <MultiSelectFilter ref="serviceLevel" filter-field="serviceLevel" option-label="name"
                      option-value="name" class="form-control form-control-select" :options="serviceLevelOptions"
                      @change="onChange" />
                  </template>
                </Column>
                <Column field="onCall" header="On-call Assignments" filter-field="onCall" 
                  :show-filter-menu="false"
                  :filter-menu-style="{ width: '14rem' }">
                  <template #filter>
                    <MultiSelectFilter ref="onCall" filter-field="onCall" option-label="name" 
                      option-value="code"
                      class="form-control form-control-select" :options="activeOptions" @change="onChange" />
                  </template>
                  <template #body="slotProps">
                    <input :id="`on-call-${slotProps.data.id}`" v-model="slotProps.data.onCall" type="checkbox"
                      class="form-check-input" @input="setDirty()">
                  </template>
                </Column>
              </template>
            </PortalTable>

            <ActionToolbar primary-button-text="Save" :component-state="currentState" :success-message="successMessage"
              :error-message="formError" :secondary-enabled="false" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { Form as VeeForm } from 'vee-validate';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useUserStore } from '@/src/stores/user';
import { pageMixin } from "@/src/mixins/pageMixin";
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames, ServiceLevels} from "@/src/enums";
import OnCallState from "@/src/components/user/_onCallState.vue";
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    PortalTable,
    Column,
    OnCallState,
    InputText,
    MultiSelectFilter
  },
  mixins: [pageMixin, savableStateMixin],
  beforeRouteLeave(to, from, next) {
    // if unsaved changes, warn user
    if (this.dirty) {
      const answer = confirm('You have unsaved changes! Are you sure you want to leave this page?')
      if (answer) { next(); }
    } else {
      next();
    }
  },
  setup() {
    const userStore = useUserStore();
    const appStore = useAppCoreStore();

    return { userStore, appStore }
  },
  data() {
    return {
      records: [],
      selectedUser: null,
      selectedTeamGroups: null,
      createNew: false,
      serviceLevelOptions: Object.values(ServiceLevels),
      activeOptions: [
        { code: true, name: "Enabled" },
        { code: false, name: "Disabled" }
      ],
      filterValues: {
        "serviceLevel": { value: null, matchMode: FilterMatchMode.IN },
        "onCall": { value: null, matchMode: FilterMatchMode.IN },
        "teamGroup": { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      dirty: false
    }
  },
  computed: {
    hasTeamGroupAvailability() {
      return !this.selectedTeamGroups ? false : this.selectedTeamGroups.some(item => item.availability!= null);
    },
    currentUserId() {
      return this.appStore.currentUser.profileInfo.id;
    },
    showWarning() {
      return this.records.length == 0 ? false : !this.records.some(item => item.onCall == true);
    }
  },
  async mounted() {
    if (!this.appStore.currentUser.canRead(PermissionNames.INTERNAL_USER_MANAGE_ON_CALL)) {
      const error = 'Access forbidden. Please contact an administrator.'
      this.handleMajorError(error);
    } else {
      try {
        this.setSavableStateLoading();
        this.loadForm();
        this.dirty = false;
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    }
  },
  methods: {
    setDirty() {
      this.dirty = true;
    },
    async loadForm() {
      this.selectedUser = this.appStore.currentUser;

      this.selectedTeamGroups = await this.loadUserTeamGroups();
      this.parseUserTeamGroupResponse( this.selectedTeamGroups);

      if(this.hasTeamGroupAvailability) {
        this.createNew = false;
      } else {
        this.createNew = true;
      }
    },
    async loadUserTeamGroups() {
      return await this.userStore.loadUserTeamGroups();
    },
    // This will parse the team group response
    parseUserTeamGroupResponse(response) {
      let superTeams = [];
      response.map((data => { 
        superTeams.push(
          {
            id: data.availability ? data.availability.id : null,
            teamGroupId: data.team_group.id,
            teamGroup: data.team.name + ' - ' + data.team_group.name,
            serviceLevel: data.service_level,
            onCall: data.on_call
          })
      }))
      this.records = superTeams;
    },
    onSubmit() {
      if (this.createNew) {
        this.create();
      } else {
        this.update();
      }
    },
    async create() {
      this.setSavableStateProcessing();
      try {
        let payload = [];
        const userId = this.currentUserId;

        this.records.forEach((record) => {
          payload.push({
            team_group_id: record.teamGroupId,
            user_id: userId,
            service_level: record.serviceLevel,
            on_call: record.onCall
          });
        });
        
        const response = await this.userStore.createUserTeamGroupAvailabilities(payload);
        this.selectedTeamGroups = await this.loadUserTeamGroups();
        this.parseUserTeamGroupResponse(this.selectedTeamGroups);
        this.dirty = false;
        this.createNew = false;
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async update() {
      this.setSavableStateProcessing();
      try {
        let payload = [];

        this.records.forEach((record) => {
          payload.push({
            id: record.id,
            team_group_id: record.teamGroupId,
            user_id: this.currentUserId,
            service_level: record.serviceLevel,
            on_call: record.onCall
          });
        });
        
        const response = await this.userStore.updateUserTeamGroupAvailabilities(payload);
        this.selectedTeamGroups = await this.loadUserTeamGroups();
        this.parseUserTeamGroupResponse(this.selectedTeamGroups);
        this.dirty = false;
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async reload() {
      await this.appStore.loadCurrentUser();
      this.loadForm();
    },
    onFilterChange(event) {
      if (event["serviceLevel"] === "") this.$refs.serviceLevel.clearFilters();
      if (event["onCall"] === "") this.$refs.onCall.clearFilters();
    },
    handleSortChange(event) {
      // When any sort order becomes null, this will help to reset the sort back to default sort field and order
      if (event.sortOrder == null) {
        this.$refs.super_team_service_level_index.resetSort();
      }
    },
    onChange(event) {
      this.$refs.super_team_service_level_index.resetFilters(event);
    },
  }
}
</script>
