<template>
  <div>
    <RouterView name="PageHeaderBar" />
    <div class="content-wrap">
      <div class="container-fluid">
        <div id="plugin-area" class="nav-wrapper">
          <nav id="side-nav" class="page-nav" :class="{ closed: sideNavClosed }">
            <PluginToolbar :class="{ closed: sideNavClosed }" @toggle="toggleSideNav">
              <template #return-link>
                <router-link class="nav-link no-scroll" title="Return to previous page" aria-label="A control that returns to the previous page" :to="{ name: 'internal-dashboards-root' }">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
                  <span class="sr-only">
                    Return to previous page
                  </span>
                </router-link>
              </template>
            </PluginToolbar>
            <PluginNav @toggle="toggleSideNav" />
          </nav>
          <div class="page-content">
            <RouterView />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import PluginToolbar from "@/src/components/buckeye-coordinator-area/plugin_templates/PluginToolbar.vue";
import PluginNav from "@/src/components/buckeye-coordinator-area/plugin_templates/PluginNav.vue";

export default {
  components: {
    PluginToolbar,
    PluginNav
  },
  data() {
    return {
      sideNavClosed: false
    }
  },
  methods: {
    toggleSideNav() {
      this.sideNavClosed = !this.sideNavClosed;
    }
  }
}
</script>
