<template>
  <div>
    <router-view name="PageHeaderBar" />
    <div class="content-wrap">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useAppCoreStore } from "@/src/stores/app-core";

export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        text: 'User Profile',
      },
    ]);
  },
}
</script>
