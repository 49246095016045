import { createApp } from 'vue';
import { createPinia } from 'pinia';
import jQuery from 'jquery';
import { router } from '~/src/router';
import App from '../src/App.vue';
import PrimeVue, { defaultOptions } from "primevue/config";

// Setup FA icons
import '../src/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// End Setup FA icons

// Set up bootstrap
import 'popper.js';
import 'bootstrap';

window.$ = jQuery;

const rootElementId = 'buckeye-portal-app-root';
const element = document.getElementById(rootElementId);

if (element) {
  const appStore = createPinia()
  const app = createApp(App);
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('buckeye-portal-app', App);
  app.use(router);
  app.use(appStore);
  app.use(PrimeVue, {
    zIndex: {
          modal: 1100,        //dialog, sidebar
          overlay: 9999,      //dropdown, overlaypanel
          menu: 1000,         //overlay menus
          tooltip: 1100       //tooltip
      }
  });

  app.mount(`#${rootElementId}`);
}
