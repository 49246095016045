<template>
  <ul class="menu-header-nav" aria-label="Toolbar">
    <li class="nav-item">
      <slot name="return-link" />
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#top" title="Return to the top of the page" aria-label="A control that returns to the top of the page">
        <font-awesome-icon :icon="['fas', 'arrow-up']" fixed-width />
        <span class="sr-only">
          Return to the top of the page
        </span>
      </a>
    </li>
    <li class="nav-item toggle-link">
      <a class="nav-link" href="#" title="Toggle menu visibility" aria-label="A control that toggles the screen visibility of the menu" @click.prevent="toggleSideNav">
        <span class="far fa-w fa-bars">
          <font-awesome-icon :icon="['fas', 'bars']" />
        </span>
      </a>
    </li>
  </ul>
</template>

<script lang="js">
export default {
  components: {
  },
  emits: ['toggle'], 
  methods: {
    toggleSideNav() {
      this.$emit('toggle');
    }
  }
}
</script>
