<template>
  <div class="row">
    <div :class="`save-toolbar action-row ${toolbarSize}`">
      <button v-if="primaryEnabled"
        :type="primaryButtonType" 
        :disabled="isPrimaryButtonDisabled" 
        :class="`btn btn-${primaryStyle}`" 
        @click="emitPrimaryClick($event)">
        <FontAwesomeIcon v-if="primaryButtonIcon" :icon="primaryButtonIcon" aria-hidden="true" />
        {{ primaryButtonText }}
        <FontAwesomeIcon v-if="isSavableStateProcessing" :icon="['fas', 'fa-spinner']" class="fa-spin" />
      </button>
      <button v-if="secondaryEnabled"
        :type="secondaryButtonType" 
        :disabled="isSecondaryButtonDisabled" 
        :class="`btn btn-${secondaryStyle} mr-3`" 
        @click="emitSecondaryClick($event)">
        <FontAwesomeIcon v-if="secondaryButtonIcon" :icon="secondaryButtonIcon" aria-hidden="true" />
        {{ secondaryButtonText }}
      </button>
      <!-- 
        For the class the mr-0 is needed because we float to the right and the btn adds
        a margin right that we don't want
       -->
      <button v-if="tertiaryEnabled"
        :type="tertiaryButtonType" 
        :disabled="isTertiaryButtonDisabled" 
        :class="`float-right ml-auto mr-0 btn btn-${tertiaryStyle}`" 
        @click="emitTertiaryClick($event)">
        <FontAwesomeIcon v-if="tertiaryButtonIcon" :icon="tertiaryButtonIcon" aria-hidden="true" />
        {{ tertiaryButtonText }}
      </button>
      <div v-if="showMessage" class="alert message-component d-inline-block mt-sm-1 mt-md-0"
        :class="{ 'alert-danger': currentState === SaveStates.Errored, 'alert-success': currentState === SaveStates.Success, 'alert-secondary': currentState === SaveStates.Info}"
        role="alert">
        <button type="button" class="close" aria-label="Close" @click="clearState">
          <FontAwesomeIcon :icon="['fas', 'fa-times']" aria-hidden="true" />
        </button>
        {{ currentMessage }}
        <slot name="message" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {SaveStates} from "@/src/enums";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

/**
 * This component provides a standard way to manage form buttons (save, cancel, etc) and any messages related
 * to those actions.
 *
 * Provides three buttons labelled primary, secondary and tertiary. All all options are available for all three
 * with different defaults. 
 * 
 * Overall Props (These affect the whole bar):
 *  - componentState {string <enum SaveStates> } Current state of the component. Understands: Blank (default), Processing, Info, Success and Errored. This 
 *                                               state is used to handle message type and style to show and if Processing disables all buttons.
 *                                               Default SaveStates.Blank
 *  - successMessage {string} Message to show when componentState is success. Default "Saved successfully!"
 *  - errorMessage {string} Message to show when componentState is Errored. Default "An error occurred!"
 *  - infoMessage {string} Message to show when componentState is Info. Default ""
 *  - toolbarSize {string} css colSize for the width of the toolbar, default col-12
 *  - primaryButtonIcon, secondaryButtonIcon, tertiaryButtonIcon {Array} Adds FontAwesome Icons to the button, default null
 * 
 * Per button props. All options are as props in the format typeOption, e.g. primaryEnabled. 
 * Options available:
 *  - Enabled {boolean} Show the button. Defaults [true, true, false]
 *  - ButtonText {string} What text to display in the button. Defaults [Save, Cancel, Info]
 *  - Type {string (submit | button)} Type of button to render. It's an enum, if doesn't match submit, it will render as button. Defaults: [submit, button, button]
 *  - Style {string} Button CSS class without the btn prefix. Defaults [success, outline-secondary, primary]
 *  - Disabled {boolean}  Individually disable buttons. componentState of Processing overrides them all. Defaults [false, false, false]
 * 
 * Events:
 *  - primaryClick
 *  - secondaryClick
 *  - tertiaryClick
 *  
 */
export default {
  components: {FontAwesomeIcon},
  mixins: [savableStateMixin],
  props: {
    // Current state of the component, uses SaveStates enum. Understands: Blank (default), Processing, Info, Success and Errored
    componentState: { default: SaveStates.Blank, type: String },
    
    // Message text to show
    successMessage: { default: 'Saved successfully!', type: String },
    errorMessage: { default: 'An error occurred!', type: String },
    infoMessage: { default: '', type: String },

    // Width of the toolbar
    toolbarSize: { default: 'col-sm-12', type: String },

    // Which buttons are enabled
    primaryEnabled: { default: true, type: Boolean},
    secondaryEnabled: { default: true, type: Boolean},
    tertiaryEnabled: { default: false, type: Boolean},

    // What text to display in each button
    primaryButtonText: { default: 'Save', type: String },
    secondaryButtonText: { default: 'Cancel', type: String },
    tertiaryButtonText: { default: 'Info', type: String },

    // What type of button to render
    primaryType: { default: 'submit', type: String },
    secondaryType: { default: 'button', type: String },
    tertiaryType: { default: 'button', type: String },

    // What style of button to render
    primaryStyle: { default: 'success', type: String },
    secondaryStyle: { default: 'outline-secondary', type: String },
    tertiaryStyle: { default: 'primary', type: String },

    // Individually disabled buttons
    primaryDisabled: { default: false, type: Boolean },
    secondaryDisabled: { default: false, type: Boolean },
    tertiaryDisabled: { default: false, type: Boolean },

    // Font Awesome icons for buttons
    primaryButtonIcon: { default: null, type: Array },
    secondaryButtonIcon: { default: null, type: Array },
    tertiaryButtonIcon: { default: null, type: Array },
  },
  emits: ['primaryClick', 'secondaryClick', 'tertiaryClick'],
  computed: {
    primaryButtonType() {
      return this.primaryType === 'submit' ? 'submit' : 'button';
    },
    secondaryButtonType() {
      return this.secondaryType === 'submit' ? 'submit' : 'button';
    },
    tertiaryButtonType() {
      return this.tertiaryType === 'submit' ? 'submit' : 'button';
    },
    isPrimaryButtonDisabled() {
      return this.isSavableStateProcessing || this.primaryDisabled;
    },
    isSecondaryButtonDisabled() {
      return this.isSavableStateProcessing || this.secondaryDisabled;
    },
    isTertiaryButtonDisabled() {
      return this.isSavableStateProcessing || this.tertiaryDisabled;
    },

    showMessage() {
      return [this.SaveStates.Success, this.SaveStates.Errored, this.SaveStates.Info].includes(this.currentState);
    },
    
    currentMessage() {
      if(this.currentState === this.SaveStates.Success) {
        return this.successMessage;
      } else if(this.currentState === this.SaveStates.Errored) {
        return this.errorMessage;
      } else if(this.currentState === this.SaveStates.Info) {
        return this.infoMessage;
      } else {
        return '';
      }
    }
  },
  watch: {
    // We need an internal state, so we use a bridge as it ensures
    // that parent is always becomes the source of truth if it changes.
    componentState(newVal) {
      this.currentState = newVal;
    }
  },
  created: function() {
    this.currentState = this.componentState;
  },
  methods: {
    emitPrimaryClick(evt) {
      this.$emit('primaryClick', evt);
    },
    emitSecondaryClick(evt) {
      this.$emit('secondaryClick', evt);
    },
    emitTertiaryClick(evt) {
      this.$emit('tertiaryClick', evt);
    },
    clearState() {
      this.currentState = this.SaveStates.Blank;
    }
  }
}
</script>
